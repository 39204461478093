import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { CustomerMasterDto, asTextBlock, fullName, newCustomerMaster, primaryContact } from "@modules/models";
import { tap } from "rxjs/operators";
import { CustomerService } from "../services/customer.service";

/**
 * @deprecated does nt work nicely.... infinite requests on a production build..?
 * lookup pipes on non-cached data is not a good idea
 */
@Pipe({ name: 'customerlookup', pure: false })
export class CustomerLookupPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  private currentCustomer: CustomerMasterDto;

  isBusyRequesting= false;

  constructor(private customerService: CustomerService,
    ref: ChangeDetectorRef) {
    this.asyncPipe = new AsyncPipe(ref);
  }

  ngOnDestroy() {
     this.asyncPipe.ngOnDestroy();
  }

  getCustomer(key: string): CustomerMasterDto {
    if(key && !this.isBusyRequesting){
      if(this.currentCustomer && this.currentCustomer.id === key) {
        return this.currentCustomer;
      }
      this.isBusyRequesting = true;
      return this.asyncPipe
      .transform(
        this.customerService.getCustomer(key)
        .pipe(
          tap(c => {
            this.currentCustomer=c;
            this.isBusyRequesting = false;
          })
        )
      );
    }
    // return this.asyncPipe.transform(of(newCustomerMaster()).pipe(tap(c => {
    //   this.currentCustomer=null;
    //   // this.isBusyRequesting = false;
    // })));
    return newCustomerMaster();
  }

  transform(key: string, format?: string, addressId?: string, withName?: boolean): any {
    switch(format){
      case 'addresslookup':
        return this.getCustomer(key)?.addresses.find(a => a.addressId === addressId)?.name || "";
      case 'address':
        const address = this.getCustomer(key)?.addresses.find(a => a.addressId === addressId);
        return asTextBlock(address, withName) || "";
      case 'primaryContact.fullName':
        return fullName(primaryContact(this.getCustomer(key)));
      case 'primaryContact.companyName':
        return primaryContact(this.getCustomer(key))?.companyName || "";
      case 'primaryContact.note':
        return primaryContact(this.getCustomer(key))?.note || "";
      case 'companyName':
      default:
        return this.getCustomer(key)?.companyName || "";
    }
  }
}
